@import "../../../styles/flex";
@import "../../../styles/dimensions";
@import "../../../styles/colors";

.container {
    @include flex(column, center, center);
	padding: 4rem 1rem 1rem;
	margin-bottom: 8rem;
    width: 100%;

    .content {
        @include flex(column, center, center);
		width: 90%;

		.tag {
			border-width: 0.2rem;
			border-style: solid;
			border-color: $dark-blue;
			padding: 0 2rem;
			border-radius: 2.5rem;
			margin-bottom: 2rem;
			p {
				font-size: 3rem;
				margin: 0;
				color: $dark-blue;
				@media (max-width: $tablet-width) {
					font-size: 2rem;
				 }

			}
		}

		.text {
			text-align: center;
			max-width: 50%;
			margin-top: 0.5rem;

			p {
				color: $dark-blue;
			}

			.paragraph {
				text-align: center;
			}

			.questionText {
				color: darken($secondary-color, 18%);
				font-weight: bold;
				margin-bottom: 0;
			}

			@media (max-width: $tablet-width) {
				max-width: 90%;
			 }

		}
		.form {
			width: 30%;
			margin-top: 3rem;
			min-width: 400px;
			@media (max-width: $tablet-width) {
				min-width: 100%;
				width: 100%;
			 }
		}
		@media (max-width: $tablet-width) {
			width: 100%;
		 }
    }
}
